import type { FormKitNode } from '@formkit/core'

/**
  * @privateRemarks
  * This file was generated by the FormKit CLI and should not be manually
  * edited unless you’d like to "eject" from the CLI’s ability to update it.
  *
  * @checksum - bc02cec6ddaeafc3639f267fa1164e53ecb3083d6ff5f073e617fdaf8af7df18
  * @variables - 
  * @theme - starter
  **/

/**
 * This is the theme function itself, it should be imported and used as the
 * config.rootClasses function. For example:
 *
 * ```js
 * import { theme } from './formkit.theme'
 * import { defineFormKitConfig } from '@formkit/vue'
 *
 * export default defineFormKitConfig({
 *   config: {
 *     rootClasses: theme
 *   }
 * })
 * ```
 **/
export function rootClasses(sectionName: string, node: FormKitNode): Record<string, boolean> {
  const key = `${node.props.type}__${sectionName}`
  const semanticKey = `formkit-${sectionName}`
  const familyKey = node.props.family ? `family:${node.props.family}__${sectionName}` : ''
  const memoKey = `${key}__${familyKey}`
  if (!(memoKey in classes)) {
    const sectionClasses = classes[key] ?? globals[sectionName] ?? {}
    sectionClasses[semanticKey] = true
    if (familyKey in classes) {
      classes[memoKey] = { ...classes[familyKey], ...sectionClasses }
    } else {
      classes[memoKey] = sectionClasses
    }
  }
  return classes[memoKey] ?? { [semanticKey]: true }
}

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 **/
const classes: Record<string, Record<string, boolean>> = {
  "family:button__outer": {
    "group": true,
    "flex-grow": true,
    "min-w-24": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:text-primary_font": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:button__wrapper": {
    "mb-1.5": true
  },
  "family:button__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "family:button__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "family:button__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "family:button__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "w-full": true,
    "p-5": true,
    "outline-none": true,
    "text-primary_font": true,
    "text-xl": true,
    "border": true,
    "border-details_light_grey": true,
    "focus:border-transparent": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[prefix-icon]:pl-5": true,
    "group-data-[suffix-icon]:pr-5": true,
    "focus:ring-2": true,
    "dark:border-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "family:button__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "family:button__messages": {
    "": true
  },
  "family:button__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "family:box__outer": {
    "group": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "w-full": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:box__wrapper": {
    "inline-flex": true,
    "items-center": true,
    "mb-1": true,
    "group-data-[multiple]:mb-0": true
  },
  "family:box__fieldset": {
    "": true
  },
  "family:box__legend": {
    "block": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true,
    "mb-2": true
  },
  "family:box__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true,
    "mb-1": true,
    "group-data-[multiple]:mb-2": true,
    "group-data-[multiple]:-mt-1.5": true
  },
  "family:box__inner": {
    "": true
  },
  "family:box__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "peer": true,
    "absolute": true,
    "h-0": true,
    "w-0": true,
    "overflow-hidden": true,
    "opacity-0": true
  },
  "family:box__decorator": {
    "relative": true,
    "block": true,
    "select-none": true,
    "w-6": true,
    "aspect-square": true,
    "border": true,
    "border-details_light_grey": true,
    "mr-1.5": true,
    "text-transparent": true,
    "peer-checked:text-white": true,
    "peer-checked:bg-tertiary": true,
    "peer-disabled:cursor-not-allowed": true,
    "peer-focus:ring-2": true,
    "dark:border-neutral-100": true,
    "dark:peer-checked:text-neutral-100": true
  },
  "family:box__decoratorIcon": {
    "absolute": true,
    "left-1/2": true,
    "top-1/2": true,
    "flex": true,
    "h-full": true,
    "w-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true
  },
  "family:box__options": {
    "": true
  },
  "family:box__option": {
    "mb-1.5": true,
    "last:mb-0": true,
    "data-[disabled]:opacity-30": true,
    "group-data-[disabled]:data-[disabled]:opacity-100": true
  },
  "family:box__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true,
    "!mb-0": true
  },
  "family:box__optionHelp": {
    "text-neutral-600": true,
    "text-xs": true,
    "-mt-1": true,
    "mb-1": true,
    "dark:text-secondary_font": true
  },
  "family:box__messages": {
    "": true
  },
  "family:box__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "family:text__outer": {
    "group": true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:text__wrapper": {
    "mb-1.5": true,
    "flex": true,
    "flex-col": true,
    "items-start": true,
    "justify-start": true,
    "last:mb-0": true
  },
  "family:text__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "text-xl": true,
    "dark:text-neutral-100": true
  },
  "family:text__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-neutral-600": true,
    "dark:text-secondary_font": true
  },
  "family:text__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-neutral-600": true,
    "dark:text-secondary_font": true
  },
  "family:text__inner": {
    "text-base": true,
    "w-full": true,
    "p-5": true,
    "rounded": true,
    "border-2": true,
    "border-details_light_grey": true,
    "flex": true,
    "items-center": true,
    "focus-within:ring-2": true,
    "focus-within:ring-tertiary": true,
    "focus-within:border-transparent": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "dark:border-neutral-100": true
  },
  "family:text__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "text-base": true,
    "text-primary_font": true,
    "min-h-[1.5em]": true,
    "bg-transparent": true,
    "grow": true,
    "border-none": true,
    "p-0": true,
    "focus:ring-0": true,
    "dark:text-neutral-100": true
  },
  "family:text__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "family:text__messages": {
    "": true
  },
  "family:text__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "family:dropdown__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:dropdown__wrapper": {
    "mb-1.5": true
  },
  "family:dropdown__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__inner": {
    "relative": true,
    "flex": true,
    "items-center": true,
    "border": true,
    "border-details_light_grey": true,
    "focus-within:ring-2": true,
    "rounded-none": true,
    "group-data-[is-multiline]:!rounded-none": true,
    "dark:border-neutral-100": true
  },
  "family:dropdown__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "!ml-2": true,
    "!mr-0": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!mr-2": true,
    "!ml-0": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "grow": true,
    "p-2": true,
    "pr-0": true,
    "pl-3": true,
    "text-base": true,
    "text-primary_font": true,
    "text-ellipsis": true,
    "min-w-0": true,
    "outline-none": true,
    "bg-transparent": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "border-none": true,
    "focus:ring-0": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__selectionWrapper": {
    "grow": true,
    "flex": true,
    "items-center": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__selection": {
    "grow": true,
    "text-primary_font": true,
    "group-data-[multiple]:p-2": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__tagsWrapper": {
    "w-full": true
  },
  "family:dropdown__tags": {
    "inline-flex": true,
    "flex-wrap": true,
    "items-center": true,
    "w-full": true,
    "-mb-1": true,
    "empty:mb-0": true
  },
  "family:dropdown__tagWrapper": {
    "group/tag": true,
    "mr-1": true,
    "mb-1": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true
  },
  "family:dropdown__tag": {
    "relative": true,
    "flex": true,
    "items-center": true,
    "text-sm": true,
    "pl-2": true,
    "pr-[1.5em]": true,
    "py-2": true,
    "h-[1.66em]": true,
    "bg-neutral-900": true,
    "rounded-none": true,
    "text-white": true,
    "dark:bg-neutral-100": true,
    "dark:text-black": true
  },
  "family:dropdown__tagLabel": {
    "": true
  },
  "family:dropdown__controlLabel": {
    "absolute": true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true
  },
  "family:dropdown__listboxButton": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "text-base": true,
    "flex": true,
    "items-center": true,
    "text-primary_font": true,
    "z-10": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__removeSelection": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "text-base": true,
    "flex": true,
    "items-center": true,
    "text-primary_font": true,
    "z-10": true,
    "dark:text-neutral-100": true,
    "group-[]/tag:absolute": true,
    "group-[]/tag:top-1/2": true,
    "group-[]/tag:right-[0.5em]": true,
    "group-[]/tag:-translate-y-1/2": true,
    "group-[]/tag:!w-[0.5em]": true,
    "group-[]/tag:aspect-square": true,
    "group-[]/tag:!text-inherit": true
  },
  "family:dropdown__selectIcon": {
    "text-base": true,
    "inline-flex": true,
    "justify-center": true,
    "w-[2.5em]": true,
    "relative": true,
    "my-auto": true,
    "[&>svg]:w-[1em]": true,
    "[&>svg]:mx-auto": true
  },
  "family:dropdown__closeIcon": {
    "text-base": true,
    "w-[0.75em]": true,
    "relative": true,
    "m-auto": true
  },
  "family:dropdown__loaderIcon": {
    "animate-spin": true,
    "flex": true,
    "items-center": true,
    "my-auto": true,
    "ml-2": true,
    "text-base": true,
    "text-primary_font": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__dropdownWrapper": {
    "rounded-none": true,
    "empty:hidden": true,
    "border": true,
    "border-details_light_grey": true,
    "dark:border-neutral-100": true,
    "group-data-[overscroll]:shadow-none": true,
    "group-data-[overscroll]:border-none": true
  },
  "family:dropdown__listbox": {
    "bg-white": true,
    "rounded-none": true,
    "overflow-clip": true,
    "empty:hidden": true,
    "dark:bg-neutral-900": true,
    "group-data-[overscroll]:border": true,
    "group-data-[overscroll]:border-details_light_grey": true,
    "group-data-[overscroll]:dark:border-neutral-100": true
  },
  "family:dropdown__listitem": {
    "relative": true,
    "flex": true,
    "items-center": true,
    "px-2": true,
    "py-1.5": true,
    "first:pt-2": true,
    "last:pb-2": true,
    "text-primary_font": true,
    "text-base": true,
    "data-[is-active]:bg-neutral-100": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "data-[is-active]:before:ring-1": true,
    "data-[is-active]:before:ring-inset": true,
    "data-[is-active]:before:ring-neutral-300": true,
    "data-[is-active]:first:before:rounded-none": true,
    "data-[is-active]:first:before:rounded-b-none": true,
    "data-[is-active]:last:before:rounded-none": true,
    "data-[is-active]:last:before:rounded-t-none": true,
    "data-[is-active]:first:last:before:rounded-none": true,
    "dark:text-neutral-100": true,
    "dark:data-[is-active]:bg-neutral-700": true,
    "dark:data-[is-active]:before:ring-neutral-400": true
  },
  "family:dropdown__selectedIcon": {
    "flex": true,
    "absolute": true,
    "items-center": true,
    "text-neutral-600": true,
    "left-2": true,
    "text-base": true,
    "h-[0.75em]": true,
    "w-[0.75em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "dark:text-secondary_font": true
  },
  "family:dropdown__option": {
    "ml-[1.25em]": true
  },
  "family:dropdown__optionLoading": {
    "": true
  },
  "family:dropdown__emptyMessage": {
    "flex": true,
    "items-center": true,
    "px-2": true,
    "py-1.5": true,
    "first:pt-2": true,
    "last:pb-2": true,
    "text-primary_font": true,
    "text-sm": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__emptyMessageInner": {
    "": true
  },
  "family:dropdown__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__messages": {
    "": true
  },
  "family:dropdown__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "button__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-full": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:text-primary_font": true,
    "data-[disabled]:pointer-events-none": true
  },
  "button__wrapper": {
    "mb-1.5": true
  },
  "button__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "button__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "button__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "button__input": {
    "appearance-none": true,
    "outline-none": true,
    "bg-tertiary": true,
    "text-white": true,
    "font-bold": true,
    "rounded": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "button__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "button__messages": {
    "": true
  },
  "button__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "checkbox__outer": {
    "group": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "checkbox__wrapper": {
    "mb-1.5": true
  },
  "checkbox__fieldset": {
    "": true
  },
  "checkbox__legend": {
    "block": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "checkbox__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "checkbox__inner": {
    "": true
  },
  "checkbox__options": {
    "": true
  },
  "checkbox__option": {
    "": true
  },
  "checkbox__decorator": {
    "rounded-sm": true,
    "peer-checked:bg-tertiary": true
  },
  "checkbox__decoratorIcon": {
    "max-w-[66.66%]": true
  },
  "checkbox__label": {
    "block": true,
    "text-base": true,
    "font-semibold": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "checkbox__optionHelp": {
    "": true
  },
  "checkbox__messages": {
    "": true
  },
  "checkbox__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "color__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "color__wrapper": {
    "mb-1.5": true
  },
  "color__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "color__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "color__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "color__inner": {
    "!w-auto": true
  },
  "color__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "rounded-none": true,
    "overflow-clip": true,
    "[&::-webkit-color-swatch-wrapper]:p-0": true,
    "[&::-webkit-color-swatch]:border-none": true,
    "[&::-moz-color-swatch]:border-none": true
  },
  "color__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "color__messages": {
    "": true
  },
  "color__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "date__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "date__wrapper": {
    "mb-1.5": true
  },
  "date__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "date__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "date__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "date__inner": {
    "": true
  },
  "date__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "date__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "date__messages": {
    "": true
  },
  "date__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "datetime-local__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "datetime-local__wrapper": {
    "mb-1.5": true
  },
  "datetime-local__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "datetime-local__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "datetime-local__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "datetime-local__inner": {
    "": true
  },
  "datetime-local__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "datetime-local__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "datetime-local__messages": {
    "": true
  },
  "datetime-local__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "email__outer": {
    "group": true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "email__wrapper": {
    "mb-1.5": true
  },
  "email__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "email__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "email__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "email__inner": {
    "": true
  },
  "email__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "email__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "email__messages": {
    "": true
  },
  "email__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "file__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "file__wrapper": {
    "mb-1.5": true
  },
  "file__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "file__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "file__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "file__inner": {
    "relative": true,
    "group-data-[multiple]:rounded-none": true
  },
  "file__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "text-transparent": true,
    "absolute": true,
    "inset-0": true,
    "opacity-0": true,
    "z-10": true,
    "file:pointer-events-none": true,
    "file:w-0": true,
    "file:h-0": true,
    "file:overflow-hidden": true
  },
  "file__fileList": {
    "group/list": true,
    "peer": true,
    "grow": true,
    "min-w-0": true
  },
  "file__fileItemIcon": {
    "h-[1em]": true,
    "w-[1em]": true,
    "mr-2": true,
    "shrink-0": true
  },
  "file__fileItem": {
    "flex": true,
    "min-w-0": true,
    "items-center": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "file__fileName": {
    "truncate": true,
    "min-w-0": true,
    "w-full": true,
    "shrink": true
  },
  "file__fileRemove": {
    "flex": true,
    "items-center": true,
    "text-primary_font": true,
    "text-[0px]": true,
    "peer-data-[has-multiple]:text-xs": true,
    "peer-data-[has-multiple]:shrink-0": true,
    "appearance-none": true,
    "outline-none": true,
    "relative": true,
    "z-20": true,
    "dark:text-neutral-100": true
  },
  "file__fileRemoveIcon": {
    "block": true,
    "text-base": true,
    "w-[0.75em]": true
  },
  "file__noFiles": {
    "flex": true,
    "w-full": true,
    "items-center": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "file__noFilesIcon": {
    "w-[1em]": true,
    "mr-2": true
  },
  "file__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "file__messages": {
    "": true
  },
  "file__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "form__form": {
    "w-full": true,
    "grow": true,
    "flex": true,
    "flex-col": true,
  },
  "form__actions": {
    "mt-auto": true
  },
  "form__summaryInner": {
    "group/summary": true,
    "border": true,
    "border-details_light_grey": true,
    "rounded-none": true,
    "py-2": true,
    "px-3": true,
    "dark:border-neutral-100": true
  },
  "form__summaryHeader": {
    "text-lg": true,
    "text-primary_font": true,
    "mb-2": true,
    "dark:text-neutral-100": true
  },
  "form__messages": {
    "": true
  },
  "form__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true,
    "group-[]/summary:text-sm": true
  },
  "month__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "month__wrapper": {
    "mb-1.5": true
  },
  "month__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "month__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "month__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "month__inner": {
    "": true
  },
  "month__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "month__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "month__messages": {
    "": true
  },
  "month__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "number__outer": {
    "group": true,
    "flex-grow": false,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "number__wrapper": {
    "mb-1.5": true
  },
  "number__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "number__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "number__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "number__inner": {
    "": true
  },
  "number__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "number__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "number__messages": {
    "": true
  },
  "number__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "password__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "password__wrapper": {
    "mb-1.5": true
  },
  "password__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "password__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "password__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "password__inner": {
    "": true
  },
  "password__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "password__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "password__messages": {
    "": true
  },
  "password__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "radio__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "radio__wrapper": {
    "mb-1.5": true
  },
  "radio__fieldset": {
    "": true
  },
  "radio__legend": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "dark:text-neutral-100": true
  },
  "radio__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "radio__inner": {
    "": true
  },
  "radio__options": {
    "": true
  },
  "radio__option": {
    "": true
  },
  "radio__decorator": {
    "rounded-full": true
  },
  "radio__decoratorIcon": {
    "max-w-[50%]": true
  },
  "radio__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "radio__optionHelp": {
    "": true
  },
  "radio__messages": {
    "": true
  },
  "radio__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "range__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "range__wrapper": {
    "mb-1.5": true
  },
  "range__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "range__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "range__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "range__inner": {
    "relative": true,
    "!border-none": true,
    "!ring-0": true,
    "!px-0": true,
    "!bg-transparent": true
  },
  "range__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "[&::-webkit-slider-runnable-track]:bg-neutral-300": true,
    "[&::-webkit-slider-runnable-track]:h-1.5": true,
    "[&::-webkit-slider-runnable-track]:rounded-none": true,
    "dark:[&::-webkit-slider-runnable-track]:bg-neutral-700": true,
    "[&::-moz-range-track]:bg-neutral-300": true,
    "[&::-moz-range-track]:h-1.5": true,
    "[&::-moz-range-track]:rounded-none": true,
    "dark:[&::-moz-range-track]:bg-neutral-700": true,
    "[&::-webkit-slider-thumb]:appearance-none": true,
    "[&::-webkit-slider-thumb]:w-3.5": true,
    "[&::-webkit-slider-thumb]:h-3.5": true,
    "[&::-webkit-slider-thumb]:bg-neutral-600": true,
    "[&::-webkit-slider-thumb]:rounded-full": true,
    "[&::-webkit-slider-thumb]:relative": true,
    "[&::-webkit-slider-thumb]:top-1/2": true,
    "[&::-webkit-slider-thumb]:-translate-y-1/2": true,
    "[&::-webkit-slider-thumb]:focus-visible:ring-2": true,
    "dark:[&::-webkit-slider-thumb]:bg-neutral-400": true,
    "[&::-moz-range-thumb]:appearance-none": true,
    "[&::-moz-range-thumb]:border-none": true,
    "[&::-moz-range-thumb]:w-3.5": true,
    "[&::-moz-range-thumb]:h-3.5": true,
    "[&::-moz-range-thumb]:bg-neutral-600": true,
    "[&::-moz-range-thumb]:rounded-full": true,
    "[&::-moz-range-thumb]:focus-visible:ring-2": true,
    "dark:[&::-moz-range-thumb]:bg-neutral-400": true
  },
  "range__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "range__messages": {
    "": true
  },
  "range__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "search__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "search__wrapper": {
    "mb-1.5": true
  },
  "search__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "search__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "search__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "search__inner": {
    "": true
  },
  "search__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "search__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "search__messages": {
    "": true
  },
  "search__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "select__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "rounded": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "select__wrapper": {
    "mb-1.5": true
  },
  "select__label": {
    "block": true,
    "text-primary_font": true,
    "text-xl": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "select__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "ml-2": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "select__suffixIcon": {
    "flex": true,
    "items-center": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "mr-2": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "select__inner": {
    "relative": true,
    "flex": true,
    "items-center": true,
    "border-2": true,
    "rounded": true,
    "border-details_light_grey": true,
    "focus-within:border-transparent": true,
    "focus-within:ring-2": true,
    "focus-within:ring-tertiary": true,
    "group-data-[multiple]:rounded-none": true,
    "dark:border-neutral-100": true,
  },
  "select__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "grow": true,
    "p-5": true,
    "text-base": true,
    "text-primary_font": true,
    "text-ellipsis": true,
    "min-w-0": true,
    "outline-none": true,
    "bg-transparent": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "data-[placeholder]:text-primary_font": true,
    "dark:text-neutral-100": true,
    "dark:data-[placeholder]:text-neutral-100": true,
    "border-none": true,
    "focus:ring-0": true,
    "bg-none": true
  },
  "select__selectIcon": {
    "absolute": true,
    "w-[1em]": true,
    "text-primary_font": true,
    "pointer-events-none": true,
    "right-2": true,
    "group-data-[suffix-icon]:mr-[1.5em]": true,
    "dark:text-neutral-100": true
  },
  "select__optGroup": {
    "group/optgroup": true,
    "font-bold": true,
    "text-sm": true
  },
  "select__option": {
    "group-data-[multiple]:text-xl": true,
    "group-data-[multiple]:font-bold": true,
    "group-data-[multiple]:outline-none": true,
    "group-data-[multiple]:border-none": true,
    "group-data-[multiple]:py-1.5": true,
    "group-data-[multiple]:px-2": true
  },
  "select__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "select__messages": {
    "": true
  },
  "select__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "submit__outer": {
    "": true
  },
  "submit__wrapper": {
    "mb-1.5": true
  },
  "submit__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "submit__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "submit__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "submit__input": {
    "appearance-none": true,
    "outline-none": true,
    "bg-tertiary": true,
    "text-white": true,
    "font-bold": true,
    "rounded": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "submit__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "submit__messages": {
    "": true
  },
  "submit__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "tel__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "tel__wrapper": {
    "mb-1.5": true
  },
  "tel__label": {
    "block": true,
    "text-primary_font": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "tel__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "tel__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "tel__inner": {
    "": true
  },
  "tel__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "tel__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "tel__messages": {
    "": true
  },
  "tel__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "text__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "text__wrapper": {
    "mb-1.5": true
  },
  "text__label": {
    "block": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "text__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "text__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "text__inner": {
    "": true
  },
  "text__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "text__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "text__messages": {
    "": true
  },
  "text__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "textarea__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "textarea__wrapper": {
    "mb-1.5": true
  },
  "textarea__label": {
    "block": true,
    "mb-1": true,
    "text-xl": true,
    "dark:text-neutral-100": true
  },
  "textarea__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "!ml-2": true,
    "!mr-0": true,
    "text-neutral-600": true,
    "dark:text-secondary_font": true
  },
  "textarea__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!mr-2": true,
    "!ml-0": true,
    "text-neutral-600": true,
    "dark:text-secondary_font": true
  },
  "textarea__inner": {
    "flex": true,
    "items-center": true,
    "mb-1.5": true,
    "border-2": true,
    "border-details_light_grey": true,
    "focus-within:border-transparent": true,
    "rounded": true,
    "focus-within:ring-2": true,
    "dark:border-neutral-100": true
  },
  "textarea__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "text-base": true,
    "h-26": true,
    "text-primary_font": true,
    "min-w-0": true,
    "grow": true,
    "shrink": true,
    "p-5": true,
    "outline-none": true,
    "bg-transparent": true,
    "dark:text-neutral-100": true,
    "p-0": true,
    "border-none": true,
    "focus:ring-0": true
  },
  "textarea__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "textarea__messages": {
    "": true
  },
  "textarea__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "time__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "time__wrapper": {
    "mb-1.5": true
  },
  "time__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "time__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "time__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "time__inner": {
    "": true
  },
  "time__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "time__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "time__messages": {
    "": true
  },
  "time__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "url__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "url__wrapper": {
    "mb-1.5": true
  },
  "url__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "url__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "url__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "url__inner": {
    "": true
  },
  "url__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "url__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "url__messages": {
    "": true
  },
  "url__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "week__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "week__wrapper": {
    "mb-1.5": true
  },
  "week__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "week__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "week__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "week__inner": {
    "": true
  },
  "week__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "week__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "week__messages": {
    "": true
  },
  "week__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "autocomplete__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "autocomplete__wrapper": {
    "mb-1.5": true
  },
  "autocomplete__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__inner": {
    "": true
  },
  "autocomplete__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "autocomplete__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "autocomplete__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "autocomplete__selections": {
    "flex": true,
    "absolute": true,
    "inset-0": true,
    "group-data-[multiple]:static": true,
    "group-data-[multiple]:block": true,
    "group-data-[empty]:hidden": true,
    "group-data-[multiple]:mt-1.5": true
  },
  "autocomplete__selectionWrapper": {
    "bg-neutral-100": true,
    "rounded-none": true,
    "group-data-[multiple]:border": true,
    "group-data-[multiple]:border-details_light_grey": true,
    "group-data-[multiple]:mb-1.5": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
    "dark:bg-neutral-700": true,
    "dark:group-data-[multiple]:border-neutral-100": true,
    "[&.formkit-dropZone]:opacity-50": true,
    "[&.formkit-touchDropZone]:opacity-50": true,
    "[&.formkit-touchDragging]:!flex": true,
    "[&.formkit-longTouch]:opacity-50": true
  },
  "autocomplete__selection": {
    "rounded-none": true,
    "pl-2": true,
    "[&>*]:ml-0": true
  },
  "autocomplete__loaderIcon": {
    "animate-spin": true,
    "flex": true,
    "items-center": true,
    "my-auto": true,
    "ml-2": true,
    "text-base": true,
    "text-primary_font": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__removeSelection": {
    "": true
  },
  "autocomplete__listboxButton": {
    "": true
  },
  "autocomplete__selectIcon": {
    "": true
  },
  "autocomplete__dropdownWrapper": {
    "": true
  },
  "autocomplete__listbox": {
    "": true
  },
  "autocomplete__listitem": {
    "": true
  },
  "autocomplete__selectedIcon": {
    "": true
  },
  "autocomplete__option": {
    "": true
  },
  "autocomplete__emptyMessage": {
    "": true
  },
  "autocomplete__emptyMessageInner": {
    "": true
  },
  "autocomplete__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__messages": {
    "": true
  },
  "autocomplete__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "colorpicker__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "colorpicker__wrapper": {
    "mb-1.5": true
  },
  "colorpicker__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__inner": {
    "relative": true,
    "inline-flex": true,
    "!w-auto": true,
    "pl-2": true,
    "group-data-[inline]:border-none": true,
    "group-data-[inline]:p-0": true,
    "group-data-[inline]:outline-none": true,
    "group-data-[inline]:!ring-0": true,
    "group-data-[inline]:!w-full": true,
    "group-data-[inline]:rounded-none": true
  },
  "colorpicker__swatchPreview": {
    "flex": true,
    "items-center": true,
    "rounded-none": true
  },
  "colorpicker__canvasSwatchPreviewWrapper": {
    "mr-2": true,
    "relative": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:rounded-none": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:z-[2]": true
  },
  "colorpicker__canvas": {
    "block": true,
    "w-full": true
  },
  "colorpicker__canvasSwatchPreview": {
    "text-base": true,
    "rounded-none": true,
    "aspect-square": true,
    "shrink-0": true,
    "grow": true,
    "!w-[1.5em]": true
  },
  "colorpicker__valueString": {
    "inline-block": true,
    "text-sm": true,
    "text-primary_font": true,
    "font-mono": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__panel": {
    "absolute": true,
    "top-full": true,
    "left-0": true,
    "z-20": true,
    "flex": true,
    "w-[100vw]": true,
    "max-w-[18.5em]": true,
    "touch-manipulation": true,
    "flex-col": true,
    "rounded-none": true,
    "border": true,
    "bg-white": true,
    "p-2": true,
    "dark:bg-neutral-900": true,
    "group-data-[inline]:static": true,
    "group-data-[inline]:z-auto": true,
    "group-data-[inline]:w-full": true,
    "group-data-[inline]:max-w-none": true,
    "group-data-[inline]:bg-transparent": true,
    "group-data-[inline]:border": true,
    "border-details_light_grey": true,
    "dark:border-neutral-100": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none": true
  },
  "colorpicker__panelClose": {
    "flex": true,
    "justify-end": true,
    "items-center": true,
    "text-primary_font": true,
    "mb-1.5": true,
    "border-b": true,
    "border-details_light_grey": true,
    "pb-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "colorpicker__closeIcon": {
    "w-[1.75rem]": true,
    "aspect-square": true,
    "p-1": true,
    "rounded-none": true,
    "border": true,
    "border-details_light_grey": true,
    "dark:border-neutral-100": true,
    "[&>svg]:w-full": true,
    "[&>svg]:aspect-square": true,
    "[&>svg]:max-w-none": true,
    "[&>svg]:max-h-none": true
  },
  "colorpicker__control": {
    "absolute": true,
    "bg-white": true,
    "shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true,
    "-translate-y-1/2": true,
    "-translate-x-1/2": true,
    "pointer-events-none": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:bg-neutral-900": true,
    "data-[prevent-focus-style]:outline-none": true,
    "data-[prevent-focus-style]:ring-0": true,
    "data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true
  },
  "colorpicker__controlGroup": {
    "grid": true,
    "[grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e']": true,
    "mb-2": true
  },
  "colorpicker__LS": {
    "[grid-area:a]": true,
    "relative": true,
    "mb-2": true
  },
  "colorpicker__canvasLS": {
    "aspect-[2/1]": true,
    "cursor-pointer": true,
    "rounded-none": true
  },
  "colorpicker__controlLS": {
    "w-[10px]": true,
    "h-[10px]": true,
    "rounded-full": true
  },
  "colorpicker__preview": {
    "[grid-area:b]": true,
    "relative": true,
    "inline-flex": true,
    "w-[2em]": true,
    "aspect-square": true,
    "overflow-hidden": true,
    "rounded-none": true,
    "after:content-['']": true,
    "after:absolute": true,
    "after:inset-0": true,
    "after:rounded-none": true,
    "after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true
  },
  "colorpicker__hue": {
    "[grid-area:c]": true,
    "relative": true,
    "inline-flex": true,
    "h-3/4": true,
    "ml-2": true
  },
  "colorpicker__canvasHue": {
    "rounded-none": true
  },
  "colorpicker__controlHue": {
    "w-[4px]": true,
    "top-1/2": true,
    "h-[calc(100%-2px)]": true,
    "rounded-none": true
  },
  "colorpicker__alpha": {
    "[grid-area:d]": true,
    "relative": true,
    "inline-flex": true,
    "h-3/4": true,
    "ml-2": true
  },
  "colorpicker__canvasAlpha": {
    "rounded-none": true
  },
  "colorpicker__controlAlpha": {
    "w-[4px]": true,
    "top-1/2": true,
    "h-[calc(100%-2px)]": true,
    "rounded-none": true
  },
  "colorpicker__eyeDropper": {
    "[grid-area:e]": true,
    "w-[2em]": true,
    "ml-2": true,
    "self-center": true,
    "justify-center": true,
    "justify-self-center": true,
    "inline-flex": true,
    "content-center": true,
    "items-center": true,
    "aspect-square": true,
    "cursor-pointer": true,
    "rounded-none": true,
    "border": true,
    "border-details_light_grey": true,
    "text-primary_font": true,
    "dark:border-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__eyeDropperIcon": {
    "w-auto": true,
    "[&>svg]:w-[1em]": true
  },
  "colorpicker__formatField": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "grow": true
  },
  "colorpicker__colorInputGroup": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "grow": true
  },
  "colorpicker__fieldGroup": {
    "flex": true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "w-full": true,
    "mr-1": true,
    "[&>input]:p-1": true,
    "[&>input]:text-sm": true,
    "[&>input]:text-primary_font": true,
    "[&>input]:m-0": true,
    "[&>input]:grow": true,
    "[&>input]:shrink": true,
    "[&>input]:w-full": true,
    "[&>input]:border": true,
    "[&>input]:border-details_light_grey": true,
    "[&>input]:rounded-none": true,
    "[&>input]:text-center": true,
    "[&>input]:appearance-none": true,
    "[&>input::-webkit-outer-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:m-0": true,
    "[&>input:focus]:outline-none": true,
    "[&>input:focus]:ring-2": true,
    "max-[431px]:[&>input]:text-base": true,
    "dark:[&>input]:text-neutral-100": true,
    "dark:[&>input]:border-neutral-100": true
  },
  "colorpicker__colorField": {
    "bg-transparent": true,
    "text-primary_font": true,
    "border": true,
    "border-details_light_grey": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "colorpicker__fieldLabel": {
    "text-xs": true,
    "text-primary_font": true,
    "mt-2": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__formatSwitcher": {
    "flex": true,
    "justify-end": true,
    "self-start": true,
    "shrink-0": true,
    "p-1": true,
    "mt-0.5": true,
    "text-primary_font": true,
    "select-none": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__switchIcon": {
    "[&>svg]:w-3": true
  },
  "colorpicker__swatches": {
    "inline-flex": true,
    "flex-wrap": true,
    "w-full": true,
    "justify-self-center": true,
    "mx-auto": true,
    "pt-2": true,
    "pb-2": true,
    "mt-2": true,
    "-mb-2": true,
    "border-t": true,
    "border-details_light_grey": true,
    "overflow-auto": true,
    "max-h-[200px]": true,
    "select-none": true,
    "dark:border-neutral-100": true,
    "first:-mt-2": true,
    "first:last:-mb-2": true,
    "first:border-t-0": true
  },
  "colorpicker__swatchGroup": {
    "flex": true,
    "flex-wrap": true,
    "w-full": true,
    "mb-2": true,
    "last:mb-0": true
  },
  "colorpicker__swatchGroupLabel": {
    "ml-1": true,
    "block": true,
    "w-full": true,
    "text-sm": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__swatch": {
    "relative": true,
    "text-base": true,
    "w-[calc((100%/10)-0.5em)]": true,
    "max-w-[22px]": true,
    "m-[0.16em]": true,
    "cursor-pointer": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:pointer-events-none": true,
    "before:z-[2]": true,
    "dark:before:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.2)]": true,
    "data-[active=true]:after:content-['']": true,
    "data-[active=true]:after:block": true,
    "data-[active=true]:after:absolute": true,
    "data-[active=true]:after:w-1.5": true,
    "data-[active=true]:after:h-1.5": true,
    "data-[active=true]:after:top-1/2": true,
    "data-[active=true]:after:left-1/2": true,
    "data-[active=true]:after:pointer-events-none": true,
    "data-[active=true]:after:rounded-full": true,
    "data-[active=true]:after:-translate-x-1/2": true,
    "data-[active=true]:after:-translate-y-1/2": true,
    "data-[active=true]:after:bg-white": true,
    "data-[active=true]:after:z-[2]": true,
    "data-[active=true]:after:shadow-[0_0_0_1px_rgba(0,0,0,0.33)]": true,
    "[&>canvas]:block": true,
    "[&>canvas]:w-full": true,
    "[&>canvas]:aspect-square": true,
    "[&>canvas:focus-visible]:outline-none": true,
    "[&>canvas:focus-visible]:ring-2": true
  },
  "colorpicker__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true,
    "mt-1.5": true,
    "group-data-[inline]:-mt-1": true,
    "group-data-[inline]:mb-2": true
  },
  "colorpicker__messages": {
    "": true
  },
  "colorpicker__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "datepicker__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "datepicker__wrapper": {
    "mb-1.5": true
  },
  "datepicker__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "datepicker__inner": {
    "relative": true,
    "pl-0": true
  },
  "datepicker__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "datepicker__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "datepicker__removeSelection": {
    "": true
  },
  "datepicker__clearIcon": {
    "flex": true,
    "items-center": true,
    "ml-1": true,
    "mr-2": true,
    "text-primary_font": true,
    "text-base": true,
    "w-[0.75em]": true,
    "h-[0.75em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "datepicker__overlay": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "datepicker__overlayInner": {
    "": true
  },
  "datepicker__overlayPlaceholder": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "datepicker__overlayChar": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "datepicker__overlayEnum": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "datepicker__overlayLiteral": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "datepicker__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "pl-3": true
  },
  "datepicker__openButton": {
    "appearance-none": true,
    "border-0": true,
    "bg-transparent": true,
    "flex": true,
    "p-0": true,
    "self-stretch": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true
  },
  "datepicker__calendarIcon": {
    "text-neutral-600": true,
    "focus-visible:text-primary_font": true,
    "flex": true,
    "w-[1em]": true,
    "grow-0": true,
    "shrink-0": true,
    "self-stretch": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-h-[1em]": true,
    "[&>svg]:max-w-[1em]": true,
    "dark:text-secondary_font": true,
    "dark:focus-visible:text-neutral-100": true
  },
  "datepicker__panelWrapper": {
    "group/panel": true,
    "absolute": true,
    "min-w-[20em]": true,
    "top-full": true,
    "rounded-none": true,
    "p-4": true,
    "bg-white": true,
    "border": true,
    "border-details_light_grey": true,
    "z-10": true,
    "dark:bg-neutral-900": true,
    "dark:border-neutral-100": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:w-full": true
  },
  "datepicker__panelHeader": {
    "grid": true,
    "grid-cols-[2.5em_1fr_2.5em]": true,
    "justify-center": true,
    "items-center": true,
    "border-b-2": true,
    "border-details_light_grey": true,
    "mb-2": true,
    "pb-2.5": true,
    "dark:border-neutral-100": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:grid-cols-[2.5em_1fr_2.5em_2.5em]": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=time]/panel:grid-cols-[2.5em_1fr_2.5em]": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=month]/panel:grid-cols-[2.5em_1fr_2.5em]": true
  },
  "datepicker__panelClose": {
    "aspect-square": true,
    "border": true,
    "border-neutral-400": true,
    "rounded-none": true,
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "text-primary_font": true,
    "[&_svg]:w-[1.25em]": true,
    "dark:border-neutral-600": true,
    "dark:text-neutral-100": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=time]/panel:col-start-3": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=month]/panel:col-start-3": true
  },
  "datepicker__monthsHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true
  },
  "datepicker__yearsHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "text-primary_font": true,
    "col-start-2": true,
    "col-end-2": true,
    "dark:text-neutral-100": true
  },
  "datepicker__timeHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true
  },
  "datepicker__daysHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true
  },
  "datepicker__prev": {
    "mr-auto": true,
    "px-2.5": true,
    "py-0.5": true,
    "col-start-1": true,
    "col-end-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true
  },
  "datepicker__next": {
    "ml-auto": true,
    "px-2.5": true,
    "py-0.5": true,
    "col-start-3": true,
    "col-end-3": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true
  },
  "datepicker__prevLabel": {
    "hidden": true
  },
  "datepicker__nextLabel": {
    "hidden": true
  },
  "datepicker__prevIcon": {
    "flex": true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-primary_font": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "datepicker__nextIcon": {
    "flex": true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-primary_font": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "datepicker__panel": {
    "flex": true,
    "justify-center": true
  },
  "datepicker__months": {
    "grid": true,
    "grid-cols-3": true,
    "w-full": true
  },
  "datepicker__month": {
    "m-2": true,
    "p-2": true,
    "text-center": true,
    "text-primary_font": true,
    "bg-transparent": true,
    "aria-selected:!bg-neutral-900": true,
    "aria-selected:!text-white": true,
    "focus:outline-none": true,
    "focus:ring-2": true,
    "focus:bg-white": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "dark:text-neutral-100": true,
    "dark:aria-selected:!bg-neutral-100": true,
    "dark:aria-selected:!text-black": true,
    "dark:focus:bg-black": true
  },
  "datepicker__years": {
    "grid": true,
    "grid-cols-5": true,
    "w-full": true
  },
  "datepicker__year": {
    "text-sm": true,
    "text-center": true,
    "text-primary_font": true,
    "items-center": true,
    "m-1": true,
    "p-1": true,
    "aria-selected:!bg-neutral-900": true,
    "aria-selected:!text-white": true,
    "focus:outline-none": true,
    "focus:ring-2": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "dark:text-neutral-100": true,
    "dark:aria-selected:!bg-neutral-100": true,
    "dark:aria-selected:!text-black": true,
    "dark:focus:bg-black": true
  },
  "datepicker__weekDays": {
    "grid": true,
    "grid-cols-7": true
  },
  "datepicker__weekDay": {
    "text-primary_font": true,
    "m-1.5": true,
    "dark:text-neutral-100": true
  },
  "datepicker__week": {
    "grid": true,
    "grid-cols-7": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:pointer-events-none": true
  },
  "datepicker__dayCell": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "text-center": true,
    "text-primary_font": true,
    "w-[2.25em]": true,
    "h-[2.25em]": true,
    "m-1": true,
    "p-2": true,
    "bg-neutral-100": true,
    "aria-selected:bg-neutral-900": true,
    "aria-selected:text-white": true,
    "focus:outline-none": true,
    "focus:ring-2": true,
    "focus:bg-white": true,
    "data-[disabled=true]:opacity-50": true,
    "data-[disabled=true]:pointer-events-none": true,
    "dark:text-neutral-100": true,
    "dark:bg-neutral-700": true,
    "dark:aria-selected:bg-neutral-100": true,
    "dark:aria-selected:text-black": true,
    "dark:focus:bg-black": true
  },
  "datepicker__timeInput": {
    "w-full": true,
    "border-2": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "text-primary_font": true,
    "border-details_light_grey": true,
    "rounded-none": true,
    "py-2": true,
    "px-3": true,
    "my-2.5": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__dayButton": {
    "appearance-none": true,
    "text-primary_font": true,
    "px-2.5": true,
    "py-0.5": true,
    "border-2": true,
    "border-details_light_grey": true,
    "mx-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__monthButton": {
    "appearance-none": true,
    "text-primary_font": true,
    "px-2.5": true,
    "py-0.5": true,
    "border-2": true,
    "border-details_light_grey": true,
    "mx-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__yearButton": {
    "appearance-none": true,
    "text-primary_font": true,
    "px-2.5": true,
    "py-0.5": true,
    "border-2": true,
    "border-details_light_grey": true,
    "mx-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "datepicker__messages": {
    "": true
  },
  "datepicker__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "dropdown__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "dropdown__wrapper": {
    "mb-1.5": true
  },
  "dropdown__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "dropdown__inner": {
    "": true
  },
  "dropdown__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "dropdown__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "dropdown__selector": {
    "flex": true,
    "grow": true,
    "justify-between": true,
    "w-full": true,
    "py-2": true,
    "pl-3": true,
    "pr-0": true,
    "text-base": true,
    "text-primary_font": true,
    "text-left": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "data-[placeholder]:text-secondary_font": true,
    "dark:text-neutral-100": true,
    "dark:data-[placeholder]:text-neutral-500": true
  },
  "dropdown__placeholder": {
    "text-secondary_font": true,
    "dark:text-neutral-500": true,
    "grow": true
  },
  "dropdown__optionLoading": {
    "": true
  },
  "dropdown__selectionWrapper": {
    "": true
  },
  "dropdown__selection": {
    "[&>*]:ml-0": true
  },
  "dropdown__selectionsWrapper": {
    "w-[85%]": true,
    "overflow-hidden": true
  },
  "dropdown__selections": {
    "inline-flex": true,
    "items-center": true
  },
  "dropdown__selectionsItem": {
    "whitespace-nowrap": true,
    "mr-1": true
  },
  "dropdown__truncationCount": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "h-[1.5em]": true,
    "bg-neutral-900": true,
    "text-white": true,
    "whitespace-nowrap": true,
    "text-[11px]": true,
    "[line-height:1em]": true,
    "tracking-tighter": true,
    "leading-0": true,
    "py-px": true,
    "px-1": true,
    "shrink-0": true,
    "my-auto": true,
    "dark:bg-neutral-100": true,
    "dark:text-primary_font": true
  },
  "dropdown__tagsWrapper": {
    "": true
  },
  "dropdown__tags": {
    "": true
  },
  "dropdown__tagWrapper": {
    "[&.formkit-dropZone_.formkit-tag]:opacity-50": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-50": true
  },
  "dropdown__tag": {
    "": true
  },
  "dropdown__tagLabel": {
    "": true
  },
  "dropdown__removeSelection": {
    "": true
  },
  "dropdown__selectIcon": {
    "shrink-0": true
  },
  "dropdown__dropdownWrapper": {
    "group-data-[overscroll]:m-0": true,
    "group-data-[overscroll]:shadow-none": true
  },
  "dropdown__listbox": {
    "": true
  },
  "dropdown__emptyMessage": {
    "": true
  },
  "dropdown__emptyMessageInner": {
    "": true
  },
  "dropdown__listItem": {
    "": true
  },
  "dropdown__selectedIcon": {
    "": true
  },
  "dropdown__option": {
    "": true
  },
  "dropdown__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "dropdown__messages": {
    "": true
  },
  "dropdown__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "mask__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "mask__wrapper": {
    "mb-1.5": true
  },
  "mask__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "mask__inner": {
    "relative": true
  },
  "mask__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "mask__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "mask__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "group-data-[has-overlay]:!caret-neutral-900": true,
    "dark:group-data-[has-overlay]:!caret-neutral-100": true
  },
  "mask__overlay": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayInner": {
    "": true
  },
  "mask__overlayPlaceholder": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayChar": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayEnum": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayLiteral": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "mask__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "mask__messages": {
    "": true
  },
  "mask__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "rating__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "rating__wrapper": {
    "mb-1.5": true
  },
  "rating__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "rating__inner": {
    "flex": true,
    "w-[130px]": true,
    "items-center": true,
    "relative": true
  },
  "rating__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "rating__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "rating__itemsWrapper": {
    "w-full": true
  },
  "rating__onItems": {
    "text-yellow-400": true
  },
  "rating__onItemWrapper": {
    "[&>*]:w-full": true,
    "[&>*]:h-auto": true,
    "[&>*]:max-w-none": true,
    "[&>*]:max-h-none": true
  },
  "rating__offItems": {
    "text-secondary_font": true,
    "dark:first-letter:text-neutral-600": true
  },
  "rating__offItemWrapper": {
    "[&>*]:w-full": true,
    "[&>*]:h-auto": true,
    "[&>*]:max-w-none": true,
    "[&>*]:max-h-none": true
  },
  "rating__ratingIcon": {
    "w-full": true
  },
  "repeater__outer": {
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "group/repeater": true,
    "max-w-full": true
  },
  "repeater__fieldset": {
    "min-w-0": true
  },
  "repeater__legend": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "dark:text-neutral-100": true,
    "mb-2": true
  },
  "repeater__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true,
    "mb-2": true,
    "-mt-1": true
  },
  "repeater__inner": {
    "": true
  },
  "repeater__items": {
    "": true
  },
  "repeater__item": {
    "flex": true,
    "w-full": true,
    "mb-2": true,
    "border": true,
    "border-details_light_grey": true,
    "rounded-none": true,
    "dark:border-neutral-100": true,
    "[&.formkit-dropZone]:opacity-30": true,
    "[&.formkit-dropZone]:pointer-events-none": true
  },
  "repeater__dragHandleWrapper": {
    "relative": true,
    "w-8": true,
    "rounded-none": true,
    "rounded-tr-none": true,
    "rounded-br-none": true
  },
  "repeater__dragHandle": {
    "w-full": true,
    "h-full": true,
    "flex": true,
    "absolute": true,
    "top-0": true,
    "left-0": true,
    "cursor-grab": true,
    "active:cursor-grabbing": true
  },
  "repeater__dragHandleIcon": {
    "w-2": true,
    "m-auto": true,
    "text-neutral-500": true,
    "[&>svg>path]:fill-current": true
  },
  "repeater__content": {
    "min-w-0": true,
    "grow": true,
    "p-5": true,
    "flex": true,
    "flex-col": true,
    "align-center": true,
    "[&>div[data-type]]:max-w-none": true,
    "[&>div[data-type]:last-child]:mb-0": true
  },
  "repeater__controls": {
    "flex": true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "p-2": true,
    "[&>li]:w-[1.5em]": true,
    "[&>li]:h-[1.5em]": true,
    "[&>li]:my-1": true,
    "[&>li]:mx-auto": true,
    "[&>li]:flex": true,
    "[&>li]:items-center": true,
    "[&>li]:appearance-none": true,
    "[&>li]:justify-center": true,
    "[&>li]:aspect-square": true,
    "[&>li]:text-neutral-500": true,
    "[&>li:hover]:text-neutral-600": true,
    "group-data-[disabled]/repeater:[&>li_button]:opacity-50": true,
    "group-data-[disabled]/repeater:[&>li_button]:!text-neutral-500": true,
    "group-data-[disabled]/repeater:[&>li_button]:pointer-events-none": true,
    "dark:[&>li]:text-neutral-500": true,
    "dark:[&>li:hover]:text-neutral-200": true
  },
  "repeater__upControl": {
    "": true
  },
  "repeater__removeControl": {
    "": true
  },
  "repeater__insertControl": {
    "": true
  },
  "repeater__downControl": {
    "": true
  },
  "repeater__controlLabel": {
    "absolute": true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true
  },
  "repeater__moveDownIcon": {
    "block": true,
    "w-[0.75em]": true,
    "aspect-square": true
  },
  "repeater__moveUpIcon": {
    "block": true,
    "w-[0.75em]": true,
    "aspect-square": true
  },
  "repeater__removeIcon": {
    "block": true,
    "w-[1.25em]": true,
    "aspect-square": true
  },
  "repeater__addIcon": {
    "block": true,
    "w-[1.25em]": true,
    "aspect-square": true
  },
  "repeater__addButton": {
    "!mb-0": true,
    "group-data-[disabled]/repeater:pointer-events-none": true,
    "group-data-[disabled]/repeater:opacity-50": true,
    "group-data-[disabled]/repeater:grayscale": true
  },
  "repeater__messages": {
    "": true
  },
  "repeater__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "slider__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "slider__wrapper": {
    "mb-1.5": true
  },
  "slider__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "slider__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true,
    "mb-1": true
  },
  "slider__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-neutral-600": true,
    "dark:text-secondary_font": true
  },
  "slider__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-neutral-600": true,
    "dark:text-secondary_font": true
  },
  "slider__sliderInner": {
    "flex": true,
    "items-center": true,
    "[&>[data-type=number]]:mb-0": true,
    "[&>[data-type=number]]:ml-3": true,
    "[&>[data-type=number]]:shrink": true,
    "[&>[data-type=number]]:grow-0": true,
    "[&[data-has-mark-labels=true]_[id^=track]]:mb-3": true
  },
  "slider__track": {
    "grow": true,
    "relative": true,
    "z-20": true,
    "py-2.5": true,
    "select-none": true
  },
  "slider__chart": {
    "relative": true,
    "w-full": true,
    "flex": true,
    "items-center": true,
    "justify-between": true,
    "aspect-[3/1]": true,
    "mb-2": true,
    "z-20": true
  },
  "slider__chartBar": {
    "absolute": true,
    "bottom-0": true,
    "h-full": true,
    "bg-neutral-400": true,
    "data-[active=true]:bg-neutral-900": true,
    "dark:bg-neutral-700": true,
    "dark:data-[active=true]:bg-neutral-100": true
  },
  "slider__trackWrapper": {
    "rounded-none": true,
    "bg-neutral-200": true,
    "px-1": true,
    "dark:bg-neutral-700": true
  },
  "slider__trackInner": {
    "relative": true,
    "h-1.5": true
  },
  "slider__fill": {
    "h-full": true,
    "rounded-none": true,
    "absolute": true,
    "top-0": true,
    "-mx-1": true,
    "bg-neutral-900": true,
    "dark:bg-neutral-100": true
  },
  "slider__marks": {
    "absolute": true,
    "pointer-events-none": true,
    "inset-0": true
  },
  "slider__mark": {
    "absolute": true,
    "top-1/2": true,
    "w-[3px]": true,
    "h-[3px]": true,
    "rounded-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "bg-neutral-900": true,
    "data-[active=true]:bg-white": true,
    "dark:bg-neutral-400": true,
    "dark:data-[active=true]:bg-neutral-600": true
  },
  "slider__markLabel": {
    "absolute": true,
    "top-[calc(100%+0.5em)]": true,
    "left-1/2": true,
    "text-primary_font": true,
    "text-xs": true,
    "-translate-x-1/2": true,
    "dark:text-secondary_font": true
  },
  "slider__handles": {
    "": true
  },
  "slider__handle": {
    "group/handle": true,
    "select-none": true,
    "w-4": true,
    "h-4": true,
    "rounded-full": true,
    "bg-white": true,
    "absolute": true,
    "top-1/2": true,
    "left-0": true,
    "z-30": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "border-2": true,
    "border-details_light_grey": true,
    "focus-visible:outline-0": true,
    "focus-visible:ring-2": true,
    "data-[is-target=true]:z-20": true
  },
  "slider__handleInner": {
    "": true
  },
  "slider__tooltip": {
    "absolute": true,
    "bottom-full": true,
    "left-1/2": true,
    "-translate-x-1/2": true,
    "-translate-y-[4px]": true,
    "bg-neutral-900": true,
    "text-white": true,
    "py-1": true,
    "px-1.5": true,
    "text-xs": true,
    "leading-none": true,
    "whitespace-nowrap": true,
    "rounded-none": true,
    "opacity-0": true,
    "transition-opacity": true,
    "group-hover:opacity-100": true,
    "group-focus-visible/handle:opacity-100": true,
    "group-data-[show-tooltip=true]/handle:opacity-100": true,
    "dark:bg-neutral-100": true,
    "dark:text-primary_font": true,
    "after:content-[\"\"]": true,
    "after:absolute": true,
    "after:top-full": true,
    "after:left-1/2": true,
    "after:-translate-x-1/2": true,
    "after:-translate-y-[1px]": true,
    "after:border-4": true,
    "after:border-transparent": true,
    "after:border-t-neutral-900": true,
    "dark:after:border-t-neutral-100": true
  },
  "slider__linkedValues": {
    "flex": true,
    "items-start": true,
    "justify-between": true
  },
  "slider__minValue": {
    "relative": true,
    "grow": true,
    "!max-w-[45%]": true,
    "mb-0": true,
    "[&>div::after]:content-[\"\"]": true,
    "[&>div::after]:absolute": true,
    "[&>div::after]:top-1/2": true,
    "[&>div::after]:left-[105%]": true,
    "[&>div::after]:-translate-y-1/2": true,
    "[&>div::after]:w-[12%]": true,
    "[&>div::after]:h-[1px]": true,
    "[&>div::after]:bg-neutral-900": true
  },
  "slider__maxValue": {
    "relative": true,
    "grow": true,
    "!max-w-[45%]": true,
    "mb-0": true
  },
  "slider__messages": {
    "": true
  },
  "slider__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "taglist__outer": {
    "group": true,
    "flex-grow": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "taglist__wrapper": {
    "mb-1.5": true
  },
  "taglist__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "taglist__inner": {
    "py-2": true,
    "pr-0": true,
    "pl-0": true
  },
  "taglist__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "taglist__suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "taglist__tags": {
    "pl-3": true
  },
  "taglist__tagWrapper": {
    "[&.formkit-dropZone_.formkit-tag]:opacity-50": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-50": true
  },
  "taglist__tag": {
    "": true
  },
  "taglist__tagLabel": {
    "": true
  },
  "taglist__removeSelection": {
    "": true
  },
  "taglist__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "!p-0": true,
    "!w-[0%]": true,
    "min-w-[1em]": true,
    "inline-block": true,
    "mb-1": true
  },
  "taglist__loaderIcon": {
    "animate-spin": true,
    "flex": true,
    "items-center": true,
    "my-auto": true,
    "ml-2": true,
    "text-base": true,
    "text-primary_font": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "taglist__listboxButton": {
    "ml-auto": true,
    "shrink-0": true
  },
  "taglist__selectIcon": {
    "": true
  },
  "taglist__dropdownWrapper": {
    "": true
  },
  "taglist__listbox": {
    "": true
  },
  "taglist__emptyMessage": {
    "": true
  },
  "taglist__emptyMessageInner": {
    "": true
  },
  "taglist__listItem": {
    "": true
  },
  "taglist__selectedIcon": {
    "": true
  },
  "taglist__option": {
    "": true
  },
  "taglist__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "taglist__messages": {
    "": true
  },
  "taglist__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "toggle__outer": {
    "group": true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true,
    "max-w-none": true
  },
  "toggle__wrapper": {
    "flex": true,
    "flex-wrap": true,
    "items-center": true,
    "mb-1": true
  },
  "toggle__altLabel": {
    "block": true,
    "w-full": true,
    "mb-1.5": true,
    "text-xs": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "toggle__inner": {
    "inline-block": true,
    "mr-2": true
  },
  "toggle__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "peer": true,
    "absolute": true,
    "opacity-0": true,
    "w-0": true,
    "h-0": true
  },
  "toggle__track": {
    "relative": true,
    "min-w-[3em]": true,
    "p-0.5": true,
    "select-none": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-neutral-400": true,
    "peer-focus-visible:ring-2": true,
    "peer-checked:bg-neutral-900": true,
    "peer-checked:[&>div:last-child]:left-full": true,
    "peer-checked:[&>div:last-child]:-translate-x-full": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:left-0": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0": true,
    "dark:bg-neutral-600": true,
    "dark:peer-checked:bg-neutral-100": true
  },
  "toggle__innerLabel": {
    "absolute": true,
    "text-white": true,
    "text-[10px]": true,
    "left-full": true,
    "top-1/2": true,
    "-translate-x-full": true,
    "-translate-y-1/2": true,
    "px-1": true,
    "dark:text-primary_font": true
  },
  "toggle__thumb": {
    "relative": true,
    "w-[50%]": true,
    "aspect-square": true,
    "p-0.5": true,
    "left-0": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-white": true,
    "text-primary_font": true,
    "dark:bg-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "toggle__thumbIcon": {
    "": true
  },
  "toggle__valueLabel": {
    "font-bold": true,
    "text-xs": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "toggle__label": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "mb-1": true,
    "dark:text-neutral-100": true,
    "peer-first:mb-0": true
  },
  "toggle__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "toggle__messages": {
    "": true
  },
  "toggle__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "togglebuttons__options": {
    "group/options": true,
    "inline-flex": true,
    "data-[vertical=true]:flex-col": true
  },
  "togglebuttons__option": {
    "group/option": true
  },
  "togglebuttons__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true,
    "!px-4": true,
    "group-data-[vertical=true]/options:w-full": true,
    "justify-center": true,
    "disabled:opacity-50": true,
    "disabled:cursor-not-allowed": true,
    "group-data-[disabled]:disabled:opacity-100": true,
    "aria-[pressed=true]:bg-neutral-900": true,
    "aria-[pressed=true]:text-white": true,
    "dark:aria-[pressed=true]:bg-neutral-100": true,
    "dark:aria-[pressed=true]:text-black": true,
    "group-[]/option:!rounded-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-none": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-tr-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-br-none": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-bl-none": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-br-none": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-none": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-none": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-tl-none": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-bl-none": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tl-none": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tr-none": true,
    "group-data-[vertical=false]/options:group-[]/option:!border-r-0": true,
    "group-data-[vertical=false]/options:group-last/option:!border-r": true,
    "group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-neutral-100": true,
    "group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-neutral-900": true,
    "group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-neutral-900": true,
    "dark:group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-neutral-900": true,
    "dark:group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-neutral-100": true,
    "dark:group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-neutral-100": true,
    "group-data-[vertical=true]/options:group-[]/option:!border-b-0": true,
    "group-data-[vertical=true]/options:group-last/option:!border-b": true,
    "group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-neutral-100": true,
    "group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-neutral-900": true,
    "group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-neutral-900": true,
    "dark:group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-neutral-900": true,
    "dark:group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-neutral-100": true,
    "dark:group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-neutral-100": true
  },
  "transferlist__outer": {
    "group": true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true,
    "max-w-none": true,
    "[&_.dnd-placeholder]:bg-neutral-900": true,
    "&_.dnd-placeholder]:text-white": true,
    "dark:[&_.dnd-placeholder]:bg-neutral-100": true,
    "dark:[&_.dnd-placeholder]:text-primary_font": true
  },
  "transferlist__fieldset": {
    "": true
  },
  "transferlist__legend": {
    "block": true,
    "text-primary_font": true,
    "text-sm": true,
    "dark:text-neutral-100": true
  },
  "transferlist__help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true,
    "pb-2": true
  },
  "transferlist__wrapper": {
    "mb-1.5": true,
    "flex": true,
    "flex-col": true,
    "sm:flex-row": true,
    "justify-between": true,
    "w-full": true,
    "max-w-none": true
  },
  "transferlist__transferlist": {
    "grow": true,
    "shrink": true,
    "min-w-0": true,
    "aspect-[4/5]": true,
    "h-[350px]": true,
    "overflow-hidden": true,
    "flex": true,
    "flex-col": true,
    "border": true,
    "border-details_light_grey": true,
    "rounded-none": true,
    "select-none": true,
    "dark:border-neutral-100": true
  },
  "transferlist__transferlistHeader": {
    "flex": true,
    "text-primary_font": true,
    "text-sm": true,
    "justify-between": true,
    "items-center": true,
    "border-b": true,
    "border-details_light_grey": true,
    "py-2": true,
    "px-2.5": true,
    "dark:border-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistHeaderLabel": {
    "": true
  },
  "transferlist__transferlistHeaderItemCount": {
    "ml-auto": true,
    "text-xs": true,
    "text-center": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistControls": {
    "p-2": true,
    "border-b": true,
    "border-details_light_grey": true,
    "dark:border-neutral-100": true
  },
  "transferlist__transferlistSearch": {
    "border": true,
    "rounded-none": true,
    "border-details_light_grey": true,
    "text-primary_font": true,
    "px-2.5": true,
    "py-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "transferlist__transferlistSearchInput": {
    "w-full": true,
    "bg-transparent": true,
    "outline-none": true,
    "text-sm": true,
    "cursor-pointer": true
  },
  "transferlist__transferlistSearchClear": {
    "m-auto": true
  },
  "transferlist__closeIcon": {
    "ml-2": true,
    "text-primary_font": true,
    "select-none": true,
    "flex": true,
    "text-base": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1em]": true
  },
  "transferlist__transferlistListItems": {
    "h-full": true,
    "overflow-y-auto": true
  },
  "transferlist__transferlistListItem": {
    "bg-white": true,
    "py-2": true,
    "px-2": true,
    "text-primary_font": true,
    "ring-1": true,
    "ring-neutral-900": true,
    "aria-selected:bg-neutral-100": true,
    "data-[is-active=true]:bg-neutral-100": true,
    "relative": true,
    "flex": true,
    "items-center": true,
    "pl-[1.5em]": true,
    "[&.formkit-dropZone]:bg-neutral-100": true,
    "[&.formkit-selectionDropZone]:bg-neutral-100": true,
    "[&.formkit-touchDropZone]:bg-neutral-100": true,
    "[&.formkit-touchSelectionDropZone]:bg-neutral-100": true,
    "[&.formkit-longTouch]:bg-neutral-100": true,
    "dark:bg-neutral-900": true,
    "dark:text-neutral-100": true,
    "dark:aria-selected:bg-neutral-700": true,
    "dark:data-[is-active=true]:bg-neutral-700": true,
    "dark:ring-neutral-100": true
  },
  "transferlist__selectedIcon": {
    "absolute": true,
    "w-[0.75em]": true,
    "left-[0.5em]": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistOption": {
    "text-sm": true
  },
  "transferlist__transferListLoadMore": {
    "": true
  },
  "transferlist__source": {
    "": true
  },
  "transferlist__sourceHeader": {
    "": true
  },
  "transferlist__sourceHeaderLabel": {
    "": true
  },
  "transferlist__sourceHeaderItemCount": {
    "": true
  },
  "transferlist__sourceControls": {
    "": true
  },
  "transferlist__sourceSearch": {
    "flex": true,
    "items-center": true
  },
  "transferlist__sourceSearchInput": {
    "": true
  },
  "transferlist__sourceSearchClear": {
    "": true
  },
  "transferlist__sourceListItems": {
    "group-data-[is-max=true]:opacity-50": true
  },
  "transferlist__sourceListItem": {
    "": true
  },
  "transferlist__sourceOption": {
    "": true
  },
  "transferlist__sourceEmptyMessage": {
    "my-2": true,
    "text-center": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "transferlist__sourceLoadMore": {
    "": true
  },
  "transferlist__sourceLoadMoreInner": {
    "": true
  },
  "transferlist__target": {
    "": true
  },
  "transferlist__targetHeader": {
    "": true
  },
  "transferlist__targetHeaderLabel": {
    "": true
  },
  "transferlist__targetHeaderItemCount": {
    "": true
  },
  "transferlist__targetListItems": {
    "": true
  },
  "transferlist__targetListItem": {
    "": true
  },
  "transferlist__targetOption": {
    "": true
  },
  "transferlist__targetEmptyMessage": {
    "my-2": true,
    "text-center": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "transferlist__targetLoadMore": {
    "": true
  },
  "transferlist__emptyMessageInner": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "p-2": true,
    "text-sm": true
  },
  "transferlist__loadMoreInner": {
    "flex": true,
    "text-sm": true,
    "text-primary_font": true,
    "p-2": true,
    "[&>span]:mr-2": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferControls": {
    "inline-flex": true,
    "flex-row": true,
    "justify-center": true,
    "grow-0": true,
    "shrink": true,
    "border": true,
    "border-details_light_grey": true,
    "rounded-none": true,
    "overflow-clip": true,
    "my-2": true,
    "mx-auto": true,
    "dark:border-neutral-100": true,
    "sm:flex-col": true,
    "sm:my-auto": true,
    "sm:mx-2": true
  },
  "transferlist__controlLabel": {
    "absolute": true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true
  },
  "transferlist__transferlistButton": {
    "appearance-none": true,
    "border-none": true,
    "flex": true,
    "justify-center": true,
    "text-sm": true,
    "shrink-0": true,
    "box-content": true,
    "text-primary_font": true,
    "disabled:!text-secondary_font": true,
    "p-3": true,
    "disabled:opacity-50": true,
    "dark:text-neutral-100": true,
    "dark:disabled:!text-neutral-500": true
  },
  "transferlist__transferButtonForwardAll": {
    "": true
  },
  "transferlist__transferButtonForward": {
    "": true
  },
  "transferlist__transferButtonBackward": {
    "": true
  },
  "transferlist__transferButtonBackwardAll": {
    "": true
  },
  "transferlist__fastForwardIcon": {
    "w-4": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__moveRightIcon": {
    "w-4": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__moveLeftIcon": {
    "w-4": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__rewindIcon": {
    "w-4": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__messages": {
    "mt-2": true
  },
  "transferlist__message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "barcode__barcodeIcon": {
    "w-[1.5em]": true,
    "text-neutral-700": true,
    "cursor-pointer": true,
    "dark:text-neutral-300": true
  },
  "barcode__dialog": {
    "border-none": true,
    "outline-none": true,
    "overflow-clip": true,
    "p-0": true,
    "bg-black": true,
    "rounded-none": true,
    "w-[100%-2rem]": true,
    "max-w-[30rem]": true,
    "[&::backdrop]:bg-neutral-800/50": true
  },
  "barcode__video": {
    "w-full": true,
    "aspect-square": true,
    "object-cover": true,
    "block": true,
    "pointer-events-none": true
  },
  "barcode__closeIcon": {
    "cursor-pointer": true,
    "absolute": true,
    "bg-white": true,
    "color-neutral-700": true,
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "rounded-none": true,
    "flex": true,
    "top-2": true,
    "right-2": true,
    "z-20": true,
    "[&>svg]:w-[1.25em]": true,
    "[&>svg]:h-[1.25em]": true,
    "[&>svg]:m-auto": true
  },
  "barcode__overlay": {
    "text-primary_font": true,
    "dark:text-neutral-100": true,
    "absolute": true,
    "top-1/2": true,
    "left-1/2": true,
    "w-[min(20em,75%)]": true,
    "aspect-square": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "rounded-none": true,
    "pointer-events-none": true,
    "shadow-[0_0_0_999em_rgba(0,0,0,0.5)]": true
  },
  "barcode__overlayDecorators": {
    "absolute": true,
    "inset-0": true,
    "z-10": true
  },
  "barcode__overlayDecoratorTopLeft": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "top-0": true,
    "left-0": true,
    "border-l-4": true,
    "border-t-4": true,
    "rounded-tr-none": true,
    "rounded-bl-none": true
  },
  "barcode__overlayDecoratorTopRight": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "top-0": true,
    "right-0": true,
    "border-r-4": true,
    "border-t-4": true,
    "rounded-tl-none": true,
    "rounded-br-none": true
  },
  "barcode__overlayDecoratorBottomRight": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "bottom-0": true,
    "right-0": true,
    "border-r-4": true,
    "border-b-4": true,
    "rounded-tr-none": true,
    "rounded-bl-none": true
  },
  "barcode__overlayDecoratorBottomLeft": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "bottom-0": true,
    "left-0": true,
    "border-l-4": true,
    "border-b-4": true,
    "rounded-tl-none": true,
    "rounded-br-none": true
  },
  "multi-step__outer": {
    "group": true,
    "flex-grow": true,
    "min-w-0": true,
    "max-w-full": true,
    "h-full": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true,
    "group/multistep": true,
  },
  "multi-step__wrapper": {
    "mb-1.5": true,
    "h-full": true,
    "group/wrapper": true,
    "data-[tab-style=\"tab\"]:rounded-none": true
  },
  "multi-step__tabs": {
    "flex": true,
    "items-center": true,
    "group-data-[tab-style=\"tab\"]/wrapper:overflow-auto": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-b-0": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-details_light_grey": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-none": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-bl-none": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-br-none": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:border-neutral-100": true,
    "group-data-[tab-style=\"progress\"]/wrapper:my-6": true,
    "group-data-[tab-style=\"progress\"]/wrapper:justify-around": true,
    "group-data-[tab-style=\"progress\"]/wrapper:overflow-visible": true,
    "group-data-[tab-style=\"progress\"]/wrapper:group-data-[hide-labels=\"true\"]/wrapper:mb-3.5": true
  },
  "multi-step__tab": {
    "group/tab": true,
    "group-data-[tab-style=\"tab\"]/wrapper:relative": true,
    "group-data-[tab-style=\"tab\"]/wrapper:flex": true,
    "group-data-[tab-style=\"tab\"]/wrapper:grow": true,
    "group-data-[tab-style=\"tab\"]/wrapper:text-sm": true,
    "group-data-[tab-style=\"tab\"]/wrapper:items-center": true,
    "group-data-[tab-style=\"tab\"]/wrapper:justify-center": true,
    "group-data-[tab-style=\"tab\"]/wrapper:cursor-pointer": true,
    "group-data-[tab-style=\"tab\"]/wrapper:text-neutral-700": true,
    "group-data-[tab-style=\"tab\"]/wrapper:bg-neutral-50": true,
    "group-data-[tab-style=\"tab\"]/wrapper:py-3.5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:px-4": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-r": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-b": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-details_light_grey": true,
    "group-data-[tab-style=\"tab\"]/wrapper:last:border-r-0": true,
    "group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:bg-white": true,
    "group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:border-b-transparent": true,
    "group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:z-10": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:text-neutral-300": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:bg-neutral-950/20": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:bg-transparent": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:border-b-transparent": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:border-neutral-100": true,
    "group-data-[tab-style=\"progress\"]/wrapper:flex": true,
    "group-data-[tab-style=\"progress\"]/wrapper:flex-col": true,
    "group-data-[tab-style=\"progress\"]/wrapper:items-center": true,
    "group-data-[tab-style=\"progress\"]/wrapper:grow": true,
    "group-data-[tab-style=\"progress\"]/wrapper:shrink-0": true,
    "group-data-[tab-style=\"progress\"]/wrapper:relative": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:block": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:text-sm": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:w-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:h-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:border-4": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:border-neutral-300": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:rounded-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:bg-white": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:z-10": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:before:border-neutral-700": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:before:bg-neutral-950": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:block": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:h-1": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:w-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:absolute": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:top-[0.5em]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:left-[calc(50%+0.5em)]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:bg-neutral-300": true,
    "group-data-[tab-style=\"progress\"]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-neutral-900": true,
    "group-data-[tab-style=\"progress\"]/wrapper:last:after:hidden": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:after:bg-neutral-700": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-neutral-300": true
  },
  "multi-step__tabLabel": {
    "group-data-[tab-style=\"progress\"]/wrapper:absolute": true,
    "group-data-[tab-style=\"progress\"]/wrapper:text-neutral-800": true,
    "group-data-[tab-style=\"progress\"]/wrapper:top-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:w-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:whitespace-nowrap": true,
    "group-data-[tab-style=\"progress\"]/wrapper:text-xs": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:text-neutral-300": true
  },
  "multi-step__badge": {
    "bg-neutral-900": true,
    "absolute": true,
    "font-mono": true,
    "font-bold": true,
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "aspect-square": true,
    "[line-height:1.25rem]": true,
    "text-white": true,
    "rounded-full": true,
    "dark:bg-neutral-300": true,
    "dark:text-primary_font": true,
    "group-data-[tab-style=\"tab\"]/wrapper:text-[0.66rem]": true,
    "group-data-[tab-style=\"tab\"]/wrapper:p-1.5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:w-5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:h-5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:top-1.5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:right-1.5": true,
    "group-data-[tab-style=\"progress\"]/wrapper:w-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:h-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:p-1": true,
    "group-data-[tab-style=\"progress\"]/wrapper:text-[10px]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:[line-height:0]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:z-10": true
  },
  "multi-step__validStepIcon": {
    "w-full": true,
    "h-full": true,
    "mt-0.5": true
  },
  "multi-step__steps": {
    "px-10": true,
    "pt-8": true,
    "pb-4": true,
    "flex": true,
    "h-full": true,
    "border-0": true,
    "dark:bg-transparent": true,
    "dark:border-neutral-100": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-t-0": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-tl-none": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-tr-none": true,
    "[&_[data-type]]:max-w-none": true
  },
  "step__stepActions": {
    "flex": true,
    "justify-between": true,
    "[&>*]:grow-0": true,
  },
  "step__stepPrevious": {
    "mr-1.5": true,
    "only:w-full": true
  },
  "step__stepNext": {
    "ml-auto": true,
    "only:w-full": true
  }
};

/**
 * Globals are merged prior to generating this file — these are included for
 * any other non-matching inputs.
 **/
const globals: Record<string, Record<string, boolean>> = {
  "outer": {
    "group": true,
    "min-w-0": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-30": true,
    "data-[disabled]:pointer-events-none": true
  },
  "wrapper": {
    "mb-1.5": true
  },
  "label": {
    "block": true,
    "text-primary_font": true,
    "text-xl": true,
    "mb-1": true,
    "dark:text-neutral-100": true
  },
  "legend": {
    "block": true,
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-secondary_font": true,
    "dark:placeholder:text-neutral-500": true
  },
  "placeholder": {
    "text-secondary_font": true,
    "dark:text-neutral-500": true
  },
  "prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "suffixIcon": {
    "flex": true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "loaderIcon": {
    "animate-spin": true,
    "flex": true,
    "items-center": true,
    "my-auto": true,
    "ml-2": true,
    "text-base": true,
    "text-primary_font": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "loadMoreInner": {
    "flex": true,
    "text-sm": true,
    "text-primary_font": true,
    "p-2": true,
    "[&>span]:mr-2": true,
    "dark:text-neutral-100": true
  },
  "help": {
    "text-primary_font": true,
    "text-xs": true,
    "dark:text-neutral-100": true
  },
  "messages": {
    "": true
  },
  "message": {
    "text-error": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  "overlay": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "overlayPlaceholder": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "overlayLiteral": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "overlayChar": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  },
  "overlayEnum": {
    "text-primary_font": true,
    "dark:text-neutral-100": true
  }
};
